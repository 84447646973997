@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap/dist/css/bootstrap-grid.min.css";
@import "~bootstrap/dist/css/bootstrap-reboot.min.css";

body {
  margin: 0;
  padding: 0;
  background-color: rgb(247 248 249);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.buttonIcon {
  alignItems: "center";
  backgroundColor: "transparent";
  border: none;
  borderRadius: "50%";
  cursor: pointer;
  display: flex;
  justifyContent: center;
  minHeight: 40px;
  minWidth: 40px;
}
.icon-width > svg {
  width: 36px;
  height: 36px;
}

.buttonIcon > span > i >  svg {
  width: 30px;
  height: 30px;
  color: rgb(75, 87, 102)!important;
}