.headerQuestion {
    font-weight: bold;
    font-size: 18px!important;
    color: black!important;
}

.divQuestion {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 5rem;
    width: 100%;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
}
.divQuestion > b {
    margin-block-end: 24px;
    font-size: 1.1rem;
}