body {
  margin: 0;
  padding: 0;
  background-color: rgb(247 248 249);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.buttonIcon {
  alignItems: "center";
  backgroundColor: "transparent";
  border: none;
  borderRadius: "50%";
  cursor: pointer;
  display: flex;
  justifyContent: center;
  minHeight: 40px;
  minWidth: 40px;
}
.icon-width > svg {
  width: 36px;
  height: 36px;
}

.buttonIcon > span > i >  svg {
  width: 30px;
  height: 30px;
  color: rgb(75, 87, 102)!important;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.App-main{
  padding-bottom:60px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.navbar-brand {
    padding: 0;
}

.navbar-brand img {
    width: auto;
    height: 50px;
}

.navbar-nav > li > span {
    padding: 15px 15px;
    position: relative;
    display: block;
}

.bg-asma {
    background-color: rgba(0, 138, 250, 0.43) !important;
}

.bg-hipertension {
    background-color: rgba(250, 114, 0, 0.43) !important;
}


.headerQuestion {
    font-weight: bold;
    font-size: 18px!important;
    color: black!important;
}

.divQuestion {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 5rem;
    width: 100%;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
}
.divQuestion > b {
    -webkit-margin-after: 24px;
            margin-block-end: 24px;
    font-size: 1.1rem;
}
.withBorder {
    border-style: solid;
    border-width: 1px;
}


.navbar-brand {
    padding: 0;
}

.navbar-brand img {
    width: auto;
    height: 50px;
}

.navbar-nav > li > span {
    padding: 15px 15px;
    position: relative;
    display: block;
}

.bg-asma {
    background-color: rgba(0, 138, 250, 0.43) !important;
}

.bg-hipertension {
    background-color: rgba(250, 114, 0, 0.43) !important;
}

header {
    box-shadow: none!important;
}
.chat-div {
    margin-left: 10px;
    margin-right: 10px;
}

.your-message {
    text-align: left;
    border-radius: 1em!important;
    border-top-left-radius: 0%!important;
    display: table;
}
.my-message {
    text-align: right;
    background-color: #f5f5f5!important;
    border-radius: 1em!important;
    border-top-right-radius: 0%!important;
    display: table;
    margin-left:auto;
}
.my-message > p {
    padding: 4px;
    margin-right: 10px;
    margin-left: 10px;
}
.your-message > p {
    padding: 4px;
    margin-left: 10px;
    margin-right: 10px;

}
.time-date {
    color: #747474;
    display: table;
    font-size: 12px!important;
    margin: 2px 0 8px!important;
}

.time-date-my-message {
    color: #747474;
    display: table;
    font-size: 12px!important;
    margin: 2px 0 8px!important;
    margin-left: auto!important;
}
.chatInputWrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.textArea {
    width: 80%;
}

.chatInputWrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.textArea {
    width: 80%;
}

