.chat-div {
    margin-left: 10px;
    margin-right: 10px;
}

.your-message {
    text-align: left;
    border-radius: 1em!important;
    border-top-left-radius: 0%!important;
    display: table;
}
.my-message {
    text-align: right;
    background-color: #f5f5f5!important;
    border-radius: 1em!important;
    border-top-right-radius: 0%!important;
    display: table;
    margin-left:auto;
}
.my-message > p {
    padding: 4px;
    margin-right: 10px;
    margin-left: 10px;
}
.your-message > p {
    padding: 4px;
    margin-left: 10px;
    margin-right: 10px;

}
.time-date {
    color: #747474;
    display: table;
    font-size: 12px!important;
    margin: 2px 0 8px!important;
}

.time-date-my-message {
    color: #747474;
    display: table;
    font-size: 12px!important;
    margin: 2px 0 8px!important;
    margin-left: auto!important;
}