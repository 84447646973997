
.navbar-brand {
    padding: 0;
}

.navbar-brand img {
    width: auto;
    height: 50px;
}

.navbar-nav > li > span {
    padding: 15px 15px;
    position: relative;
    display: block;
}

.bg-asma {
    background-color: rgba(0, 138, 250, 0.43) !important;
}

.bg-hipertension {
    background-color: rgba(250, 114, 0, 0.43) !important;
}

header {
    box-shadow: none!important;
}